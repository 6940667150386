<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Candidate group</h4>
              </div>
              <div class="card-toolbar text-right">
                <v-btn x-large dark @click="addGroup()">
                  Add group
                </v-btn>
              </div>
            </div>

          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field label="Candidate group name" v-model="search.name"
                      outlined dense v-on:keyup.enter="searchCandidateGroup" @input="search.exam_key = $event !== null ? $event : ''"
                      clearable>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                      outlined
                      dense
                      :items="exam_sessions"
                      item-text="name"
                      item-value="id"
                      v-model="search.session_id"
                      label="Session"
                      :loading="examSessionLoading"
                      clearable
                      :search-input.sync="sessionSearch"
                      placeholder="Enter keyword"
                  >
                    <template v-slot:label>
                      Session (Type to search)
                    </template>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select dense outlined label="Group enrolled ?" v-model="search.is_group_enrolled" :items="[
                      {text:'All',value:null, },  {text:'Yes',value:1, },{ text:'No',value:0  }]" item-text="text" item-value="value">

                  </v-select>
                </v-col>

                <v-col cols="12" md="3" class="text-right">
                  <v-btn :loading="loading" @click="searchCandidateGroup()"  class="btn btn-primary btn-block" dark>
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading">
              </v-skeleton-loader>
              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Name</th>
                  <th class="px-3">Session</th>
                  <th class="px-3">Total candidate</th>

                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(candidateGroup, index) in candidateGroups" :key="index" >
                    <td class="px-3">
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ candidateGroup.name }}
                      </a>
                      <div>
                        <span class="badge" v-bind:class="{ 'badge-success': candidateGroup.is_active, 'badge-danger': !candidateGroup.is_active }">
                          {{ candidateGroup.is_active ? 'Active' : 'Inactive' }}
                        </span>
                        <span class="badge" v-bind:class="{ 'badge-success': candidateGroup.is_group_enrolled, 'badge-danger': !candidateGroup.is_group_enrolled }">
                          {{ candidateGroup.is_group_enrolled ? 'Group enrolled :Yes' : 'Group enrolled :No' }}
                        </span>
                      </div>
                    </td>
                    <td class="px-3">
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ candidateGroup.session_name }}
                      </a>
                    </td>
                    <td class="px-3">
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ candidateGroup.total_candidates }}
                      </a>
                    </td>

                    <td class="pr-0 text-left">
                      <template>
                        <template>
                          <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret right no-flip>
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click="editGroup(candidateGroup)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteGroup(candidateGroup.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="viewCandidateList(candidateGroup.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-users"></i>
                                </span>
                                  <span class="navi-text">View candidate lists</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="candidateGroups.length === 0" >
                    <td colspan="4" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="candidateGroups.length > 0"
                  class="pull-right mt-7"
                  @input="getAllCandidateGroups"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
        </div>
      </div>
    </div>
    </div>
    <create-or-update-group ref="create-update-group" :userId="userId" @refresh="getAllCandidateGroups" :user="account_holder"></create-or-update-group>
  </v-app>
</template>

<script>
import CandidateGroupService from "@/services/candidate/candidate_group/CandidateGroupService";
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import CreateOrUpdateGroup from "@/view/pages/view/user/general/group/CreateOrUpdateGroup.vue";

const examSession = new ExamSession();
const candidateGroup=new CandidateGroupService();
  export default {
    props:['account_holder'],
    components:{
      CreateOrUpdateGroup
    },
    data(){
      return{
        loading:false,
        total: null,
        perPage: null,
        page: null,
        pages: [],
        candidateGroups:[],
        search:{
          is_group_enrolled:null,
          session_id:null,
        },
        sessionLoading:false,
        examSessionLoading: false,
        sessionSearch:null,
        exam_sessions:[],
      }
    },
    methods:{
      deleteGroup(id){
        this.$confirm({
          message: `Are you sure? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              candidateGroup
                  .delete(id)
                  .then((response) => {
                    this.getAllCandidateGroups();
                    this.$snotify.success("Group  deleted");
                  })
                  .catch((err) => {
                    this.$snotify.error("Something went wrong");
                  });
            }
          }
        })
      },
      editGroup(item){
        this.$refs['create-update-group'].editGroup(item);
      },
      addGroup(){
        this.$refs['create-update-group'].createGroup();
      },
      searchCandidateGroup(){
        this.page=1;
        this.getAllCandidateGroups();
      },
      viewCandidateList(id){
        this.$router.replace({ name: 'candidate-group-list', params: { id: id } });

      },
      getAllCandidateGroups(){
        this.loading=true;
        this.search.user_id=this.userId;
        candidateGroup
            .paginate(this.search, this.page)
            .then((response) => {
              this.candidateGroups = response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            })
            .finally(() => {
              this.loading = false;
            });
      },
    },
    computed: {
      userId() {
        if (this.$route.params.accountholderId)
          return this.$route.params.accountholderId;
        else if (this.$route.params.examinerId)
          return this.$route.params.examinerId;
        else if (this.$route.params.onlineMarkerId)
          return this.$route.params.onlineMarkerId;
        else
          return this.$route.params.onlineMarkerId;
      },
      currentUser() {
        return this.$store.getters.currentUser;
      },
    },
    mounted() {
      this.getAllCandidateGroups();
    },
    watch:{
      sessionSearch(val) {
        this.exam_sessions = [];
        let data = {
          name:val,
          score_id: this.currentUser.score_id
        };
        this.examSessionLoading=true;
        examSession
            .search(data)
            .then((response) => {
              response.data.exam_sessions.map((session) => {
                let data = session;
                data.display_text = session.name ;
                this.exam_sessions.push(data);
              });
            })
            .catch((err) => {

            })
            .finally((err) => {
              this.examSessionLoading = false
            });

      },
    },
  }

</script>